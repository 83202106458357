import React from "react"

import FAQ from '../components/faq.js'
import FAQItem from '../components/faq-item.js'
import MarketingLayout from '../layouts/marketing.js'

export default function Home() {
  return (
    <MarketingLayout title="Carrick Issues"
                     description="Track GitHub Issues directly from Help Scout. Carrick Issues integrates your Help Scout mailbox directly with GitHub so your team can coordinate faster and serve customers better."
                     shortDescription="Track GitHub Issues directly from Help Scout">
      <div className="wrapper">
        <h1>
          <img src="/img/logo.svg" alt="Carrick Issues" />
          Carrick <span className="bold-hd">Issues</span>
        </h1>
        <h2>Track GitHub Issues <em>directly</em> from Help Scout</h2>
        <div className="cta-wrapper">
          <a className="btn cta" target="_blank" rel="noreferrer" href="https://carrick-processor.herokuapp.com/signup/issues">Try For Free</a>
        </div>

        <div className="features">
          <div className="feature">
            <h3>Create issues in <em>one click</em>.</h3>
            <img src="/img/one-click.png" alt="Create issues in one click" />
          </div>

          
          <div className="feature">
            <h3>Discuss issues <em>where your team works</em>.</h3>
            <img src="/img/team.png" alt="Discuss issues where your team works" />
          </div>

          
          <div className="feature">
            <h3>Stay informed about <em>issue status</em>.</h3>
            <img src="/img/issue-status.png" alt="Stay informed about issue status" />
          </div>
        </div>

        <div className="cta-wrapper">
          <a className="btn cta outline" target="_blank" rel="noreferrer" href="https://carrick-processor.herokuapp.com/signup/issues">Try For Free</a>
        </div>
      </div>

      <div className="plan-info">
        <h3>One price for your entire team.</h3>
        <div className="plan-header">
          <div>
            <h4>
              Carrick 
              <span>Issues</span>
              <div className="price-badge">
                <span className="price-badge-label">Just</span> 
                <span className="price-badge-price">$20/mo</span>
              </div>
            </h4>
          </div>
          <ol className="plan-features">
            <li>Help Scout Live Sidebar</li>
            <li>Customizable Issue Templates</li>
            <li>Unlimited Mailboxes and Users</li>
            <li>Unlimited Linked Repositories</li>
            <li>Issues Linked and Available Forever</li>
          </ol>
          <a className="btn" target="_blank" rel="noreferrer" href="https://carrick-processor.herokuapp.com/signup/issues">
            Install For Free
            <span>14-day Trial</span>
          </a>
        </div>
      </div>

      <FAQ>
        <FAQItem question="What issue trackers do you support?"
                 answer="Currently we support GitHub Issues. We have planned integrations with GitLab and Trello. If there's another integration you're looking for, let us know!" />

        <FAQItem question="Is this going to require access to read our code?"
                answer="Nope! You can choose just the specific repositories you want, and then the integration <b>only</b> gets access to read/write GitHub Issues." />

        <FAQItem question="Can I customize the issue text?"
                  answer="Yep! Each repository can have its own GitHub Issue template. There's a variety of merge fields you can insert into the templates to get everything exactly as you want it." />

        <FAQItem question="Can I get a list of all tickets for an issue?"
                answer="Yes! The initial comment created by Carrick will have a link that will show you all of the related Help Scout tickets." />
      </FAQ>
    </MarketingLayout>
  )
}
